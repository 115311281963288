<template>
  <div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'Tag' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-1 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST5' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-5 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST8' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-8 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST11' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-11 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST14' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-14 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST17' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-17 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST20' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-20 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST25' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-25 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST28' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-28 /></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST2' }" /></div>
        <div class="card"><vb-widgets-lists-2 /></div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST6' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-6 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST9' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-9 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST12' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-12 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST15' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-15 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST19' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-19 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST22' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-22 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST26' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-26 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST27' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-27 /></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST3' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-3 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST4' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-4 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST7' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-7 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST10' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-10 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST13' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-13 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST16' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-16 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST18' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-18 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST23' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-23 /></div>
        </div>
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST24' }" /></div>
        <div class="card">
          <div class="card-body"><vb-widgets-lists-24 /></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST21' }" /></div>
        <div class="card"><vb-widgets-lists-21 /></div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST21V1' }" /></div>
        <div class="card"><vb-widgets-lists-21v1 /></div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder"><vb-headers-tag :data="{ title: 'LIST21V2' }" /></div>
        <div class="card"><vb-widgets-lists-21v2 /></div>
      </div>
    </div>
  </div>
</template>

<script>
import VbHeadersTag from '@/@vb/widgets/Headers/Tag'
import VbWidgetsLists1 from '@/@vb/widgets/WidgetsLists/1'
import VbWidgetsLists5 from '@/@vb/widgets/WidgetsLists/5'
import VbWidgetsLists8 from '@/@vb/widgets/WidgetsLists/8'
import VbWidgetsLists11 from '@/@vb/widgets/WidgetsLists/11'
import VbWidgetsLists14 from '@/@vb/widgets/WidgetsLists/14'
import VbWidgetsLists17 from '@/@vb/widgets/WidgetsLists/17'
import VbWidgetsLists20 from '@/@vb/widgets/WidgetsLists/20'
import VbWidgetsLists25 from '@/@vb/widgets/WidgetsLists/25'
import VbWidgetsLists28 from '@/@vb/widgets/WidgetsLists/28'
import VbWidgetsLists2 from '@/@vb/widgets/WidgetsLists/2'
import VbWidgetsLists6 from '@/@vb/widgets/WidgetsLists/6'
import VbWidgetsLists9 from '@/@vb/widgets/WidgetsLists/9'
import VbWidgetsLists12 from '@/@vb/widgets/WidgetsLists/12'
import VbWidgetsLists15 from '@/@vb/widgets/WidgetsLists/15'
import VbWidgetsLists19 from '@/@vb/widgets/WidgetsLists/19'
import VbWidgetsLists22 from '@/@vb/widgets/WidgetsLists/22'
import VbWidgetsLists26 from '@/@vb/widgets/WidgetsLists/26'
import VbWidgetsLists27 from '@/@vb/widgets/WidgetsLists/27'
import VbWidgetsLists3 from '@/@vb/widgets/WidgetsLists/3'
import VbWidgetsLists4 from '@/@vb/widgets/WidgetsLists/4'
import VbWidgetsLists7 from '@/@vb/widgets/WidgetsLists/7'
import VbWidgetsLists10 from '@/@vb/widgets/WidgetsLists/10'
import VbWidgetsLists13 from '@/@vb/widgets/WidgetsLists/13'
import VbWidgetsLists16 from '@/@vb/widgets/WidgetsLists/16'
import VbWidgetsLists18 from '@/@vb/widgets/WidgetsLists/18'
import VbWidgetsLists23 from '@/@vb/widgets/WidgetsLists/23'
import VbWidgetsLists24 from '@/@vb/widgets/WidgetsLists/24'
import VbWidgetsLists21 from '@/@vb/widgets/WidgetsLists/21'
import VbWidgetsLists21v1 from '@/@vb/widgets/WidgetsLists/21v1'
import VbWidgetsLists21v2 from '@/@vb/widgets/WidgetsLists/21v2'

export default {
  name: 'VbLists',
  components: {
    VbHeadersTag,
    VbWidgetsLists1,
    VbWidgetsLists5,
    VbWidgetsLists8,
    VbWidgetsLists11,
    VbWidgetsLists14,
    VbWidgetsLists17,
    VbWidgetsLists20,
    VbWidgetsLists25,
    VbWidgetsLists28,
    VbWidgetsLists2,
    VbWidgetsLists6,
    VbWidgetsLists9,
    VbWidgetsLists12,
    VbWidgetsLists15,
    VbWidgetsLists19,
    VbWidgetsLists22,
    VbWidgetsLists26,
    VbWidgetsLists27,
    VbWidgetsLists3,
    VbWidgetsLists4,
    VbWidgetsLists7,
    VbWidgetsLists10,
    VbWidgetsLists13,
    VbWidgetsLists16,
    VbWidgetsLists18,
    VbWidgetsLists23,
    VbWidgetsLists24,
    VbWidgetsLists21,
    VbWidgetsLists21v1,
    VbWidgetsLists21v2,
  },
}
</script>
